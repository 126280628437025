export const navLinks = [
  {
    title: "About",
    url: "/about"
  },
  {
    title: "Contact",
    url: "/contact"
  }
]
