import { Page, Image, Text } from '@geist-ui/core';

const LaunchPage = () => {
  return (
    <Page>
      <Image src={process.env.PUBLIC_URL + '/img/logo.svg'} />
      <Text h1>Launching soon</Text>
      <Text>RegReport will be launching in the very near future! Until then
      check back again soon.</Text>
    </Page>
  );
}

export default LaunchPage;
