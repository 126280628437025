import { GeistProvider, CssBaseline } from '@geist-ui/core';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import routes from 'Routes';

const RedirectIfLive = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_APP_LIVE === 'false') {
      navigate('/launch');
    }
  }, [navigate]);

  return null;
};

const App = () => {
  return (
    <GeistProvider>
      <CssBaseline />
      <Router>
        <RedirectIfLive />
        <Routes>
          {Object.values(routes).map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </Router>
    </GeistProvider>
  );
}

export default App;
