import { useEffect, useState } from 'react';
import { getVehicleReport } from 'Utils/api/getVehicleReport';

const CarReport = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const { reg } = props;

  useEffect(() => {
    const fetchReports = () => {
      getVehicleReport(reg).then(response => {
        setIsLoading(false);
        setReports(response);
        console.log(response);
      }).catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    };
    fetchReports();
  }, [reg]);

  return (
    <div>
      {reports.map((report, index) => (
        <div key={index}>
          <h3>{report.summary}</h3>
          <p>{report.report}</p>
          </div>
      ))}
    </div>
  );
};

export default CarReport;
