import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicleDetails } from 'Utils/api/getVehicleDetails';

import {
  Layout
} from 'Components';

import CarDetails from './Components/CarDetails';
import CarReport from './Components/CarReport';

const ReportPage = () => {
  const { reg } = useParams();
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const hasFetched = useRef(false);

  const fetchDetails = () => {
    getVehicleDetails(reg).then(response => {
      setIsLoading(false);
      setVehicleDetails(response);
      console.log(response);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    });
  }

  useEffect(() => {
    if (reg && !hasFetched.current) {
      hasFetched.current = true;
      fetchDetails();
    }
  },[reg]);

  return (
    <>
      <Layout
        pageTitle="Your Vehicle Report • RegReport"
        content={
          isLoading ? (
            <div>Loading...</div>
          ) : (
            vehicleDetails && (
              <div className="box">
                  <CarDetails data={vehicleDetails} />
                  <CarReport reg={vehicleDetails.registration_number} />
              </div>
            )
          )
        }
      />
    </>
  );
}

export default ReportPage;
