import CheckRegistrationForm from '../CheckRegistrationForm';

import style from './hero.module.css';

import bannerImg from './hero_banner.png';

const inlineStyles = {
  backgroundImage: `url(${bannerImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '30%',
  backgroundPosition: 'right center',
};

const Hero = () => {
  return (
    <div className={style['hero']} style={inlineStyles}>
      <div className={style['content']}>
        <h1 className={style['title']}>
          Welcome to <strong>Reg</strong>Report - your go to platform for sharing and discovering vehicle history.
        </h1>
        <p>Simply enter your car's registration number to access user-contributed reports
          on its condition, history, or past issues.</p>
        <CheckRegistrationForm />
        <p className={style['helper']}>RegReport accepts UK registration plates only</p>
      </div>
    </div>
  );
}

export default Hero;
