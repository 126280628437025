import { navLinks } from 'Routes/nav';
import { Link, NavLink } from 'react-router-dom';
import { Page } from '@geist-ui/core';
import usePageTitle from 'Hooks/usePageTitle';

import Footer from './Components/Footer';

import style from './layout.module.css';

const Layout = (props) => {
  const { pageTitle, content, hasContentPadding } = props;
  usePageTitle(pageTitle);

  return (
    <>
      <div className={style['header']}>
        <Link to="/">
          <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="Logo" />
        </Link>
        <div>
          {navLinks.map((link, index) => (
            <NavLink
              className={({ isActive }) => `${style['link']} ${isActive ? style['active'] : ''}`}
              key={index}
              to={link.url}
            >
              {link.title}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={hasContentPadding ? style['padding'] : ''}>
        {content}
      </div>
      <Footer />
    </>
  );
}

export default Layout;
