import { useEffect } from 'react';
import setPageTitle from 'Utils/setPageTitle';

const usePageTitle = (title) => {
  useEffect(() => {
    setPageTitle(title);
  }, [title]);
};

export default usePageTitle;
