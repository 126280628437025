import style from './footer.module.css';

const Footer = () => {
  return (
    <div className={style['container']}>
      &copy; 2024 regreport.co.uk
    </div>
  );
}

export default Footer;
