import style from './hero.module.css';

const HeroBanner = (props) => {
  const { title, subtitle, image } = props;
  return (
    <div className={style['hero']}>
      {image && <img src={image} className={style['image']} alt="Hero banner" />}
      <h1 className={style['title']}>{title}</h1>
      <p>{subtitle}</p>
    </div>
  );
}

export default HeroBanner;
