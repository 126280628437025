import {
  HomePage,
  ReportPage,
  AboutPage,
  LaunchPage
} from 'Pages';

const routes = {
  home: {
    path: '/',
    component: HomePage,
  },
  report: {
    path: '/report/:reg',
    component: ReportPage,
  },
  about: {
    path: '/about',
    component: AboutPage,
  },
  launch: {
    path: '/launch',
    component: LaunchPage
  }
};

export default routes;
