import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './form.module.css';

const CheckRegistrationForm = () => {
  const navigate = useNavigate();
  const [reg, setReg] = useState('');

  const handleClick = () => {
    navigate(`/report/${reg}`);
  }

  return (
    <div className={style['container']}>
      <input
        className={style['input']}
        type="text"
        placeholder="Enter REG"
        value={reg}
        onChange={(e) => setReg(e.target.value)}
      />
      <a className={style['button']} onClick={handleClick}>Check Report</a>
    </div>
  );
}

export default CheckRegistrationForm;
