import {
  Grid,
  Card,
  Text,
  Display,
  Image
} from '@geist-ui/core';

import { Link } from 'react-router-dom';

const IntroBlock = () => {
  return (
    <Grid.Container gap={2}>
      <Grid xs={24} md={12}>
        <Display shadow caption="Each report will generate a score card">
          <Image src={process.env.PUBLIC_URL + '/img/reportcard.png'} />
        </Display>
      </Grid>
      <Grid xs={24} md={12}>
        <Card>
          <Text h4 my={0}>Your Car's Report Card</Text>
          <Text>Always 100% free, our user-generated report cards provide valuable
            insights into your current or future car purchase.</Text>
          <Card.Footer>
            <Link to="/about">View more information</Link>
          </Card.Footer>
        </Card>
      </Grid>
    </Grid.Container>
  );
}

export default IntroBlock;
