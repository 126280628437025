import { request, api_url } from 'Utils/request';
import { formatRegistration } from 'Utils/formatRegistration';

export function getVehicleDetails(registrationNumber) {
  return request({
    url: api_url('vehicle'),
    method: "POST",
    body: JSON.stringify({ registrationNumber: formatRegistration(registrationNumber) })
  });
}
