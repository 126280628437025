import { Text, Grid, Page, Collapse } from '@geist-ui/core';

import {
  Layout,
  HeroBanner,
  PageContainer
} from 'Components';

import faqData from 'Data/About/faqs.json';
import lemonImage from './lemon.svg';

const AboutPage = () => {

  return (
    <Layout
      pageTitle="About RegReport • User generated vehicle reports"
      hasContentPadding
      content={
        <>
          <HeroBanner
            image={lemonImage}
            title="Helping you steer clear of a lemon!"
            subtitle="We help give car owners the power to share their experiences"
          />
          <PageContainer>
            <Grid.Container gap={2}>
              <Grid xs={12}>
                <div>
                  <Text h3>User powered, 100% free forever</Text>
                  <Text>
                    Vehicle checks only tell you so much. Our user-generated report
                    cards provide valuable insights into your current or
                    future car purchase.
                  </Text>
                  <Text>
                    Car rejected? Electrical or engine trouble? Share your experience
                    with others and help them avoid a lemon.
                  </Text>
                </div>
              </Grid>
              <Grid xs={12}>
                <Collapse.Group>
                  {faqData.map((faq, index) => (
                    <Collapse key={index} title={faq.title}>
                      <Text>{faq.content}</Text>
                    </Collapse>
                  ))}
                </Collapse.Group>
              </Grid>
            </Grid.Container>
          </PageContainer>
        </>
      }
    />
  );
}

export default AboutPage;
