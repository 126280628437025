import style from './car.module.css';

const CarDetails = (props) => {
  const { data } = props;

  return (
    <div className={`flex two ${style['banner']}`}>
      <div>
        <h1 className={style['reg']}>{data.registration_number}</h1>
      </div>
      <div>
        <p><strong>Make: </strong>{data.data.make}</p>
        <p><strong>Colour: </strong>{data.data.colour}</p>
        <p><strong>Engine Capacity: </strong>{data.data.engineCapacity}cc</p>
      </div>
    </div>
  );
}

export default CarDetails;
