import {
  Layout,
  PageContainer
} from 'Components';

import Hero from './Components/Hero';
import IntroBlock from './Components/IntroBlock';

const HomePage = () => {
  return (
    <Layout
      header={<h2>Header</h2>}
      pageTitle="RegReport • User generated vehicle reports"
      content={
        <>
          <Hero />
          <PageContainer>
            <IntroBlock />
          </PageContainer>
        </>
      }
    />
  );
}

export default HomePage;
